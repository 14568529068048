<template>
	<div class="clock">
		{{ currentDate }}
		<span id="clock"></span>
	</div>
</template>

<script>
export default {
	name: 'Clock',

	components: {},

	data: () => ({
		currentDate: ''
	}),
	mounted() {
		this.updateCurrentDate()
		setInterval(this.updateClock, 1000)
	},
	computed: {},
	methods: {
		updateClock() {
			const clockElement = document.getElementById('clock')
			if (!clockElement) return
			const now = new Date()
			const hours = String(now.getHours()).padStart(2, '0')
			const minutes = String(now.getMinutes()).padStart(2, '0')
			const seconds = String(now.getSeconds()).padStart(2, '0')
			clockElement.textContent = `${hours}:${minutes}:${seconds}`
			if (minutes == '00') this.updateCurrentDate()
		},
		updateCurrentDate() {
			const options = {
				weekday: 'long',
				year: 'numeric',
				month: 'long',
				day: 'numeric'
			}
			this.currentDate = new Date().toLocaleDateString('zh-CN', options)
		}
	}
}
</script>
<style>
body {
	font-weight: bolder;
}

.full-width {
	width: 100%;
	max-width: 800px;
}

.image-preview {
	max-width: 100px;
	margin: 5px;
}

.image-list {
	display: flex;
	flex-wrap: wrap;
}

.merchant-name {
	font-size: 4rem;
	color: #f0f0f0;
	background-color: rgba(34, 139, 34, 0.8);
	/* 深绿色背景色 */
	padding: 0.1em 0.3em;
	/* 添加一些内边距 */
	border-radius: 50px;
	/* 左右两端是半圆 */
}

.merchant-card {
	background-color: rgba(255, 255, 255, 0.1);
	/* color: white; */
}

.merchant-card-title {
	/* color: #F0F0F0; */
	font-weight: bolder;
	position: relative;
	display: inline-block;
	padding: 0 1em;
	margin: 0.2em 0;
}

.merchant-card-title::before,
.merchant-card-title::after {
	content: '';
	position: absolute;
	top: 50%;
	width: 80px;
	height: 3px;
	background: linear-gradient(to right, #4caf50, #81c784);
}

.merchant-card-title::before {
	left: -60px;
}

.merchant-card-title::after {
	right: -60px;
}

.merchant-card .q-card-section {
	padding: 0;
}

#profile-page {
	width: 100vw;
	height: 100vh;
	/* border: 1px solid #000; */
	margin: 0;
	background: linear-gradient(135deg, #a8e063 0%, #56ab2f 100%);
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	color: #333;
	font-family: Arial, sans-serif;
}

#profile-page::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: radial-gradient(
		circle,
		rgba(255, 255, 255, 0.2) 1px,
		transparent 1px
	);
	background-size: 50px 50px;
	pointer-events: none;
	/* Keeps the texture from interfering with interactivity */
	opacity: 0.4;
	/* Slight transparency for a subtle effect */
}

.clock {
	font-size: 1.5em;
	text-align: center;
	/* padding: 20px; 添加内边距 */
	border-radius: 10px;
	/* 圆角边框 */
	/* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); 添加阴影 */
	background-color: rgba(255, 255, 255, 0.1);
	/* 半透明背景 */
}
</style>
