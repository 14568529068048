import Vue from 'vue'

import VueRouter from 'vue-router'
Vue.use(VueRouter)

let router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			name: 'index',
			path: '/',
			component: () => import('@/pages/index.vue')
		},
		{
			name: 'lab',
			path: '/lab',
			component: () => import('@/pages/lab.vue')
		}
		// {
		// 	path: '*',
		// 	redirect: '/404'
		// } //如果没有任何match，则跳至默认页面（或者404）
	]
})

export default router
