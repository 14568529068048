<template>
	<div id="scroll-board">
		<dv-scroll-board :config="config" />
	</div>
</template>

<script>
import { tradeSummary } from '@/api'
import { format, subDays } from 'date-fns'
export default {
	name: 'ScrollBoard',
	props: { days: { type: [String, Number], default: 7 } },
	data() {
		return {
			fromTime: null,
			toTime: null,
			config: {
				header: ['日期', '星期', '交易金额', '交易笔数', '客单价'],
				data: [],
				index: true,
				columnWidth: [50, 170],
				align: ['center'],
				rowNum: 7,
				headerBGC: '#1981f6',
				headerHeight: 45,
				oddRowBGC: 'rgba(0, 44, 81, 0.8)',
				evenRowBGC: 'rgba(10, 29, 50, 0.8)'
			},
			interval: null
		}
	},
	created() {
		this.interval = setInterval(this.initData, 20 * 60 * 1000) // 10 minutes
		this.initData()
	},
	beforeDestroy() {
		if (this.interval) clearInterval(this.interval)
	},
	methods: {
		initData() {
			this.fromTime = format(subDays(new Date(), this.days), 'yyyy-MM-dd')
			this.toTime = format(subDays(new Date(), 0), 'yyyy-MM-dd')
			tradeSummary(this.fromTime, this.toTime).then((res) => {
				// console.log(res.data.data)
				this.config.data = res.data.data.map((item) => {
					return [
						item.day,
						item.weekday,
						item.amount,
						item.num,
						item.avg
					]
				})
				this.config = { ...this.config }
			})
		}
	}
}
</script>

<style lang="less">
#scroll-board {
	width: 50%;
	box-sizing: border-box;
	margin-left: 20px;
	height: 100%;
	overflow: hidden;
}
</style>
