<template>
	<dv-full-screen-container style="height: 100vh">
		<div id="data-view" style="">
			<top-header />

			<div class="main-content" style="max-height: 95%; height: 85%">
				<digital-flop :fromTime="fromTime" :toTime="toTime" />

				<div class="block-left-right-content">
					<!-- <div
						class="block-top-bottom-content"
						style="max-width: 20%"
					>
						<temphum /> -->
					<ranking-board />
					<!-- </div> -->
					<div class="block-top-bottom-content">
						<div class="block-top-content">
							<rose-chart />
							<scroll-board
								:fromTime="fromTime"
								:toTime="toTime"
							/>
							<water-level-chart />
						</div>

						<transaction />
					</div>
				</div>
			</div>
			<div style="height: 20px; text-align: end; padding: 0 20px">
				技术支持：鄂尔多斯市乐创科技有限公司
			</div>
		</div>
	</dv-full-screen-container>
</template>

<script>
/**
 * http://datav.jiaminghi.com/guide/
 */
import topHeader from '../components/topHeader.vue'
import digitalFlop from '../components/digitalFlop.vue'
import rankingBoard from '../components/rankingBoard.vue'
import roseChart from '../components/roseChart.vue'
import waterLevelChart from '../components/waterLevelChart'
import scrollBoard from '../components/scrollBoard'
import transaction from '../components/transaction'
import temphum from '../components/temphum.vue'
import qs from 'qs'
export default {
	name: 'DataView',
	props: [],
	components: {
		topHeader,
		digitalFlop,
		rankingBoard,
		roseChart,
		waterLevelChart,
		scrollBoard,
		transaction,
		temphum
	},
	data() {
		const qps = qs.parse(location.search, { ignoreQueryPrefix: true })
		return {
			fromTime: qps.fromTime,
			toTime: qps.toTime
		}
	},
	methods: {}
}
</script>

<style lang="less">
#data-view {
	width: 100%;
	height: 100%;
	background-color: #030409;
	color: #fff;

	#dv-full-screen-container {
		background-image: url('./bg.png');
		background-size: 100% 100%;
		box-shadow: 0 0 3px blue;
		display: flex;
		flex-direction: column;
	}

	.main-content {
		flex: 1;
		display: flex;
		flex-direction: column;
	}

	.block-left-right-content {
		flex: 1;
		display: flex;
		// margin-top: 20px;
	}

	.block-top-bottom-content {
		flex: 1;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		padding-left: 20px;
	}

	.block-top-content {
		height: 55%;
		display: flex;
		flex-grow: 0;
		box-sizing: border-box;
		padding-bottom: 20px;
	}
}
</style>
