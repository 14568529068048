import request from '@/util/http'
import http from '@/util/http2'
export function getTempHum() {
	let url = `/api/tempHum`
	return http.get(url)
}
// 交易额和交易笔数
export function getMerchant(fromTime = null, toTime = null) {
	let url = `/datav/summary?`
	if (fromTime) url += `&fromTime=${fromTime}`
	if (toTime) url += `&toTime=${toTime}`
	return request({
		method: 'GET',
		url
	})
}

export function getTest() {
	return request({
		method: 'GET',
		url: '/datav/test/summary'
	})
}

export function getMarket() {
	return request({
		method: 'GET',
		url: '/datav/market/summary'
	})
}

export function getTrade(fromTime = null, toTime = null) {
	let url = '/datav/trade/numberByDay?'
	if (fromTime) url += `&startCreatedAt=${fromTime}`
	if (toTime) url += `&endCreatedAt=${toTime}`
	return request({
		method: 'GET',
		url
	})
}

export function topNumber() {
	return request({
		method: 'GET',
		url: '/datav/top5/number'
	})
}
export function topAmount() {
	return request({
		method: 'GET',
		url: '/datav/top5/amount'
	})
}

export function tradeSummary(fromTime = null, toTime = null) {
	let url = '/datav/trade/summary?'
	if (fromTime) url += `&fromTime=${fromTime}`
	if (toTime) url += `&toTime=${toTime}`
	console.log('tradeSummary', url)
	return request({
		method: 'GET',
		url
	})
}

export function amountByCategory() {
	return request({
		method: 'GET',
		url: '/datav/amountByCategory'
	})
}
