<template>
	<div id="digital-flop1">
		<div
			class="digital-flop-item1"
			v-for="item in digitalFlopData"
			:key="item.title"
		>
			<div class="digital-flop-title1">{{ item.title }}</div>
			<div class="digital-flop1">
				<dv-digital-flop
					:config="item.number"
					style="width: 100px; height: 50px"
				/>
				<div class="unit1">{{ item.unit }}</div>
			</div>
		</div>

		<!-- <dv-decoration-10 /> -->
	</div>
</template>

<script>
import { getTempHum } from '@/api'

export default {
	name: 'TempHum',
	props: [],
	data() {
		return {
			digitalFlopData: []
		}
	},
	methods: {
		createData() {
			getTempHum().then((res) => {
				this.digitalFlopData = [
					{
						title: '温度',
						number: {
							number: [res.data.data[0].temp],
							content: '{nt}',
							textAlign: 'right',
							style: {
								fill: '#4d99fc',
								fontWeight: 'bold'
							}
						},
						unit: '°C'
					},
					{
						title: '湿度',
						number: {
							number: [res.data.data[0].hum],
							content: '{nt}',
							textAlign: 'right',
							style: {
								fill: '#f46827',
								fontWeight: 'bold'
							}
						},
						unit: '%'
					}
				]
			})
		}
	},
	mounted() {
		const { createData } = this

		createData()

		setInterval(createData, 30000)
	}
}
</script>

<style lang="less">
#digital-flop1 {
	position: relative;
	height: 15%;
	// flex-shrink: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	// background-color: rgba(6, 30, 93, 0.5);

	// .dv-decoration-10 {
	// 	position: absolute;
	// 	width: 95%;
	// 	left: 2.5%;
	// 	height: 5px;
	// 	bottom: 0px;
	// }

	.digital-flop-item1 {
		// width: 100%;
		height: 80%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		// border-left: 3px solid rgb(6, 30, 93);
		// border-right: 3px solid rgb(6, 30, 93);
	}

	.digital-flop-title1 {
		flex: 1;
		justify-content: center;
		font-size: 20px;
		// margin-bottom: 20px;
	}

	.digital-flop1 {
		display: flex;
	}

	.unit1 {
		margin-left: 10px;
		display: flex;
		align-items: flex-end;
		box-sizing: border-box;
		padding-bottom: 13px;
	}
}
</style>
