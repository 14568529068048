<template>
	<div id="ranking-board">
		<div class="ranking-board-title">交易数量 TOP5</div>
		<dv-scroll-ranking-board :config="config" />

		<div class="ranking-board-title">交易金额 TOP5</div>
		<dv-scroll-ranking-board :config="amount" />
	</div>
</template>

<script>
import { topAmount, topNumber } from '@/api'

export default {
	name: 'RankingBoard',
	data() {
		return {
			config: {
				data: [],
				rowNum: 5
			},
			amount: {
				data: [],
				rowNum: 5
			}
		}
	},
	mounted() {
		this.createData()
		setInterval(this.createData, 30000)
	},
	methods: {
		createData() {
			topNumber().then((res) => {
				var data = res.data.data
				this.config.data = data.map((item) => {
					return {
						name: `${item.merchantName}/${item.merchantCategory}`,
						value: item.num
					}
				})

				this.config.rowNum = this.config.data.length
				this.config = { ...this.config }
				console.log(this.config)
			})
			topAmount().then((res) => {
				var data = res.data.data
				this.amount.data = data.map((item) => {
					return {
						name: `${item.merchantName}/${item.merchantCategory}`,
						value: item.amount
					}
				})
				this.amount = { ...this.amount }
				console.log(this.amount)
			})
		}
	}
}
</script>

<style lang="less">
#ranking-board {
	width: 20%;
	box-shadow: 0 0 3px blue;
	display: flex;
	flex-direction: column;
	background-color: rgba(6, 30, 93, 0.5);
	border-top: 2px solid rgba(1, 153, 209, 0.5);
	box-sizing: border-box;
	padding: 0px 30px;

	.ranking-board-title {
		font-weight: bold;
		height: 50px;
		display: flex;
		align-items: center;
		font-size: 20px;
	}

	.dv-scroll-ranking-board {
		flex: 1;
	}
}
</style>
